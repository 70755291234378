import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MENU_DRAWER_ITEMS } from 'consts/components/MenuDrawer';
import { ReactComponent as CloseBigIcon } from 'images/closeBigIcon.svg';
import { useLocation, useNavigate } from '@reach/router';
import { useOnClickOutside } from 'utils/hooks';

import {
  StyledMemuDrawerList,
  StyledMenuDrawer,
  StyledMenuDrawerItem,
  StyledMenuDrawerOverlay,
} from './styles';
import { StyledModalHeader, useModal } from 'components/Modal/Modal';
import { CreateCompanyForm } from 'containers/CompanyCreate/CreateCompanyForm';
import { WorkplaceModal } from 'components/WorkplaceModal/WorkplaceModal';
import { AddOrEditRevisedActivity } from 'dialogs/RevisedActivities';
import { ResetUserModal } from 'containers/Auth/ResetUserModal';
import { QuickSightModal } from 'containers/QuickSight/QuickSightModal';

export const CreateCompanyModalLink = ({ handleClose }) => {
  const { t } = useTranslation();
  const { showModal, setModalComponent, setCustomStyles, setShowHeader } = useModal();
  return (
    <StyledMenuDrawerItem>
      <button
        onClick={() => {
          setCustomStyles({ maxWidth: '85rem' });
          setModalComponent(
            <>
              <StyledModalHeader>{t('company_details.add_new_company')}</StyledModalHeader>
              <CreateCompanyForm numberKnown />
            </>,
          );
          setShowHeader(true);
          showModal();
          handleClose();
        }}
        type='button'
      >
        {t('top_bar.menu_drawer.create_new_business')}
      </button>
    </StyledMenuDrawerItem>
  );
};

export const CreateActivityModalLink = ({ handleClose }) => {
  const { t } = useTranslation();
  const { showModal, setModalComponent, setShowHeader } = useModal();

  return (
    <StyledMenuDrawerItem>
      <button
        onClick={() => {
          setModalComponent(<AddOrEditRevisedActivity />);
          setShowHeader(false);
          showModal();
          handleClose();
        }}
        type='button'
      >
        {t('top_bar.menu_drawer.new_activity')}
      </button>
    </StyledMenuDrawerItem>
  );
};

export const CreateWorkplaceModalLink = ({ handleClose }) => {
  const { t } = useTranslation();
  const { showModal, setModalComponent, setCustomStyles, setShowHeader } = useModal();

  return (
    <StyledMenuDrawerItem>
      <button
        onClick={() => {
          setModalComponent(<WorkplaceModal />);
          setCustomStyles({ maxWidth: '78rem' });
          setShowHeader(false);
          showModal();
          handleClose();
        }}
        type='button'
      >
        {t('new_visits.new_workplace')}
      </button>
    </StyledMenuDrawerItem>
  );
};

export const MenuDrawer = ({ handleClose, isMenuOpen, isAdmin, reports }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  useOnClickOutside(ref, () => {
    if (isMenuOpen) handleClose();
  });
  let menuWithReports = MENU_DRAWER_ITEMS;
  if (reports) {
    const newMenu = [];
    menuWithReports.forEach((item) => {
      if (!item.url?.includes('/redesigned/reports')) {
        newMenu.push(item);
      } else {
        reports.forEach((report) =>
          newMenu.push({
            label: t(item.label) + ' - ' + report.title,
            url: `${item.url}/${report.title}`,
            title: report.title,
          }),
        );
      }
    });
    menuWithReports = newMenu;
  } else {
    menuWithReports = menuWithReports.filter((item) => !item.url?.includes('/redesigned/reports'));
  }
  const menuBasedOnRole = isAdmin
    ? menuWithReports
    : menuWithReports.filter((item) => !item.withAdminRole);
  const listElements = menuBasedOnRole.map(({ label, url, title, component: Component }, index) => {
    if (url) {
      return (
        <StyledMenuDrawerItem key={index}>
          <button
            onClick={() => {
              navigate(url, {
                state: url.includes('/redesigned/reports')
                  ? { ...location.state, reportTitle: title }
                  : { ...location.state },
              });
              handleClose();
              if (/\/admin$/.test(url)) {
                window.location.reload();
              }
            }}
            type='button'
          >
            {url.includes('/redesigned/reports') ? label : t(label)}
          </button>
        </StyledMenuDrawerItem>
      );
    } else if (Component) {
      return Component({ handleClose, key: index });
    }
    return null;
  });
  return (
    <>
      <StyledMenuDrawerOverlay isVisible={isMenuOpen} />
      <StyledMenuDrawer isMenuOpen={isMenuOpen} ref={ref}>
        <button type='button'>
          <CloseBigIcon onClick={() => handleClose()} />
        </button>
        <StyledMemuDrawerList>{listElements}</StyledMemuDrawerList>
      </StyledMenuDrawer>
    </>
  );
};

export const CreateResetUserModalLink = ({ handleClose }) => {
  const { t } = useTranslation();
  const { showModal, setModalComponent } = useModal();

  return (
    <StyledMenuDrawerItem>
      <button
        onClick={() => {
          setModalComponent(<ResetUserModal />);
          showModal();
          handleClose();
        }}
        type='button'
      >
        {t('top_bar.menu_drawer.reset_user')}
      </button>
    </StyledMenuDrawerItem>
  );
};

export const CreateQuickSightModalLink = ({ handleClose }) => {
  const { t } = useTranslation();
  const { showModal, setModalComponent } = useModal();

  return (
    <StyledMenuDrawerItem>
      <button
        onClick={() => {
          setModalComponent(<QuickSightModal />);
          showModal();
          handleClose();
        }}
        type='button'
      >
        {t('top_bar.menu_drawer.quick_sight_management')}
      </button>
    </StyledMenuDrawerItem>
  );
};
