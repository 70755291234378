import { API } from 'api';
import { AxiosError } from 'axios';

export async function runExportJob() {
  try {
    const response = await API.post('quick_sight/export', {});
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function runImportJob(fileKey: string) {
  const params = { file_key: fileKey };
  try {
    const response = await API.post('quick_sight/import', params);
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}

export async function fetchImportKeys() {
  try {
    const response = await API.get('quick_sight/import_list', {});
    return response.data;
  } catch (e) {
    console.log(e);
    throw (e as AxiosError).message;
  }
}
