import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { StyledModalHeader, useModal } from 'components/Modal/Modal';
import { fetchImportKeys, runExportJob, runImportJob } from './helpers';
import { useLoadingContext } from 'contexts/LoadingContext';
import { DefaultButton } from 'components/DefaultButton/DefaultButton';
import { Margin } from 'styles/utils';
import { StyledInput, StyledInputWrapper } from './styles';
import { FileList } from './FileList';

export const QuickSightModal = () => {
  const [fileKey, setFileKey] = React.useState('');
  const [list, setList] = React.useState([]);
  const { setLoading, isLoading, setLoaded } = useLoadingContext();
  const { t } = useTranslation();
  const { hideModal } = useModal();
  const handleFileKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileKey(e.target.value);
  };

  useEffect(() => {
    try {
      setLoading('list-of-import-keys');
      fetchImportKeys().then((res) => {
        setList(res);
      });
      setLoaded('list-of-import-keys');
    } catch (e) {
      setLoaded('list-of-import-keys');
      console.log('Unable to fetch import keys', e);
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading('quick-sight-import-job');
      await runImportJob(fileKey);
      setLoaded('quick-sight-import-job');
      toast.success(t('quick_sight.import_success'));
      hideModal();
    } catch (e) {
      setLoaded('quick-sight-import-job');
      console.log(e);
      toast.error(e as string);
    }
  };

  const triggerExportJob = async () => {
    try {
      setLoading('quick-sight-export-job');
      await runExportJob();
      setLoaded('quick-sight-export-job');
      toast.success(t('quick_sight.export_success'));
    } catch (e) {
      setLoaded('quick-sight-export-job');
      console.log(e);
      toast.error(e as string);
    }
  };

  return (
    <>
      <StyledModalHeader>{t('quick_sight.export_header')}</StyledModalHeader>
      <DefaultButton
        onClick={triggerExportJob}
        label={t('quick_sight.start_export')}
        isDisabled={isLoading('quick-sight-export-job')}
        type='button'
      ></DefaultButton>
      <Margin top='10px' />
      <i>{t('quick_sight.export_wait_time')}</i>
      <Margin top='50px' />
      <form onSubmit={onSubmit}>
        <StyledModalHeader>{t('quick_sight.import_header')}</StyledModalHeader>
        <StyledInputWrapper>
          <StyledInput
            autoFocus
            aria-label='run import job'
            placeholder={t('quick_sight.file_key')}
            onChange={handleFileKey}
            disabled={isLoading('quick-sight-import-job')}
          />
          <Margin left='10px' />
          <DefaultButton
            label={t('quick_sight.start_import')}
            isDisabled={isLoading('quick-sight-import-job')}
            type='submit'
          ></DefaultButton>
        </StyledInputWrapper>
      </form>
      <FileList loading={isLoading('list-of-import-keys')} list={list} />
    </>
  );
};
