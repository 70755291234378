import styled from 'styled-components/macro';

export const StyledListHeader = styled.header`
  font-size: 15px;
  text-align: left;
  font-weight: bold;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.5em;
  align-items: center;
`;

export const StyledInput = styled.input`
  border-radius: 0.2em;
  border: 1px solid ${({ theme }) => theme.colors.black100};
  margin: 0.5em 0.5em 0.5em 0;
  height: 100%;
  padding: 1em;
  font-size: 15px;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0.3em ${({ theme }) => theme.colors.blue200};
  }
`;

export const StyledSpinnerWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 1em;
`;
