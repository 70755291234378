import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledFilesHeader, StyledFilesListItem } from 'components/Forms/Attachments/styles';
import { StyledSpinnerWrapper } from './styles';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

export const FileList = ({ list, loading }: { list: string[]; loading: boolean }) => {
  const { t } = useTranslation();

  if (list.length === 0 && !loading) {
    return <StyledFilesHeader>{t('quick_sight.no_files_found')}</StyledFilesHeader>;
  }

  return (
    <>
      <StyledFilesHeader>{t('quick_sight.list_of_files')}:</StyledFilesHeader>
      {loading ? (
        <StyledSpinnerWrapper>
          <LoadingSpinner small />
        </StyledSpinnerWrapper>
      ) : (
        <ul>
          {list.map((item, i) => (
            <StyledFilesListItem key={i}>{item}</StyledFilesListItem>
          ))}
        </ul>
      )}
    </>
  );
};
